import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  TextField,
  Paper,
  Box,
  Divider,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  ErrorOutline,
  CheckCircle,
  Edit,
  List,
  AddCircleOutline,
  Upload as UploadIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { parsePdfFile } from '../utilities/parseUtils';
import { useAppContext } from '../contexts/AppContext';
import HierarchicalVisualization from './AssistantCheckHierarchy';
import config from '../config';

const RecommendationWorkflow = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState({});
  const [actionLog, setActionLog] = useState([]);
  const [showSections, setShowSections] = useState(false);
  const [error, setError] = useState(null);
  const [pdfContent, setPdfContent] = useState(null);
  const { queryGenerativeModel } = useAppContext();
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [hierarchy, setHierarchy] = useState(null);
  const [processingStatus, setProcessingStatus] = useState({
    message: '',
    severity: 'info', // 'info', 'success', 'warning', or 'error'
    isProcessing: false
  });
  
  const model_params = {
    model: config.models.mid.model,
    provider: config.models.extra_large.provider,
    response_format: 'json_object'
  };

  async function processPdfContent(pdfData) {
    if (!pdfData || !pdfData.text) {
      throw new Error('Ungültige PDF-Daten: Text fehlt');
    }

    setProcessingStatus({
      message: 'Starte Dokumentenanalyse...',
      severity: 'info',
      isProcessing: true
    });
  
    const CHUNK_SIZE = 4000;
    const chunks = splitTextIntoChunks(pdfData.text, CHUNK_SIZE);
    
    let allSections = [];
    let contextFromPreviousChunk = {};

    setProcessingStatus({
      message: `Dokumentenanalyse: ${chunks.length} Textabschnitte identifiziert`,
      severity: 'info',
      isProcessing: true
    });
  
    console.log('Starting document processing with parameters:', {
      numberOfChunks: chunks.length,
      modelConfig: model_params,
      firstChunkPreview: chunks[0].substring(0, 100) + '...'
    });
  
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      const isFirstChunk = i === 0;
      const isLastChunk = i === chunks.length - 1;

      setProcessingStatus({
        message: `Analysiere Textabschnitt ${i + 1} von ${chunks.length}`,
        severity: 'info',
        isProcessing: true
      });
  
      const structurePrompt = {
        role: "system",
        content: `Du bist ein Experte für die Analyse von Rechtsdokumenten.
        Dies ist ${isFirstChunk ? 'der erste' : isLastChunk ? 'der letzte' : 'ein mittlerer'} Teil eines längeren Dokuments.
        ${!isFirstChunk ? 'Berücksichtige den Kontext aus dem vorherigen Teil.' : ''}
        
        Analysiere den folgenden Rechtstext und identifiziere seine hierarchische Struktur.
        Beachte dabei besonders:
        1. Hauptabschnitte (oft mit § oder Nummern gekennzeichnet und kein Dezimalpunkt)
        2. Detailabschnitte (alle weiteren Untergliederungen)
        
        ${!isFirstChunk ? 'Vorheriger Kontext: ' + JSON.stringify(contextFromPreviousChunk) : ''}
        
        Important 'identifier' rules:
        - **Root Sections:** Identified by the '§' symbol followed by a whole number (no decimals). It may optionally include a title.
          - Example: §1, §1 Title

        - **Subsections:** Identified by a hierarchical numbering system, where the first number corresponds to the section, followed by a period ('.') and a secondary number.
          - Example: 1.1 (subsection of §1), 2.3 (subsection of §2)

        - **Nested Subsection:** Further levels follow the same pattern, e.g., 1.1.1 (sub-subsection of 1.1). There is no predefined depth limit.

        ### Expected Output:
        - Extract all sections and subsections while preserving their hierarchical structure.
        - Retain unique identifiers and titles (if present).
        - Format the output as a structured list, tree, or JSON-like format to reflect parent-child relationships.

        ### Examples of Proper Identifiers:
        - **Root Section:** §1 or §1 Title
        - **Subsection:** 1.1, 1.2, 1.2 Title
        - **Nested Subsections:** 1.1.1, 1.1.2 Title

        Ensure that all extracted identifiers are unique within the document.
        
        Erstelle eine präzise JSON-Struktur mit:
        {
          "sections": [
            {
              "identifier": "einzigartiger_abschnitts_identifier",
              "type": "abschnittstyp", // root section, subsection, nested subsection, etc.
              "title": "abschnittstitel_falls_vorhanden",
              "text": "vollständiger_abschnittstext",
              "parentIdentifier": "identifier_des_übergeordneten_abschnitts",
              "childIdentifiers": ["identifier_der_untergeordneten_abschnitte"],
              "isPartial": boolean,
              "continuesFromIdentifier": "identifier_des_vorherigen_teils",
              "continuesInIdentifier": "identifier_des_nächsten_teils"
            }
          ],
          "context": {
            "lastIncompleteIdentifier": "identifier_des_letzten_unvollständigen_abschnitts",
            "openHierarchyIdentifiers": ["identifier_der_offenen_hierarchieebenen"]
          }
        }`
      };
  
      const documentPrompt = {
        role: "user",
        content: chunk
      };
  
      try {
        console.log(`Processing chunk ${i + 1} of ${chunks.length}, length: ${chunk.length} characters`);
        
        let structureResponse;
        try {
          structureResponse = await queryGenerativeModel(
            model_params.provider,
            model_params.model,
            [structurePrompt, documentPrompt],
            model_params.response_format
          );
          
          console.log(`Received response for chunk ${i + 1}, response type:`, typeof structureResponse);
          if (structureResponse === null) {
            console.error('Model returned null response');
            throw new Error('Modell-Antwort ist null');
          }
          if (typeof structureResponse !== 'string') {
            console.error('Unexpected response type:', typeof structureResponse);
            throw new Error(`Unerwarteter Antworttyp: ${typeof structureResponse}`);
          }
        } catch (modelError) {
          console.error('Model query error:', modelError);
          throw new Error(`Fehler bei der Modellanfrage: ${modelError.message}`);
        }
  
        let chunkStructure;
        try {
          chunkStructure = JSON.parse(structureResponse);
          console.log(`Successfully parsed JSON for chunk ${i + 1}`);
        } catch (parseError) {
          console.error('JSON Parse Error:', parseError);
          console.error('Raw response:', structureResponse);
          throw new Error('Ungültiges JSON-Format in der Modellantwort');
        }
  
        if (!chunkStructure || !chunkStructure.sections) {
          console.error('Invalid structure:', chunkStructure);
          throw new Error('Ungültige Strukturantwort vom Modell');
        }
  
        contextFromPreviousChunk = chunkStructure.context || {};
        
        const newSections = Array.isArray(chunkStructure.sections) ? chunkStructure.sections : [];
        console.log(`Processing ${newSections.length} sections from chunk ${i + 1}`);
        
        allSections = mergeSections(allSections, newSections);
        console.log(`Total sections after merge: ${allSections.length}`);
  
      } catch (error) {
        console.error(`Detailed error processing chunk ${i + 1}:`, error);
        throw new Error(`Fehler bei der Verarbeitung von Chunk ${i + 1}: ${error.message}`);
      }
    }
  
    if (allSections.length === 0) {
      throw new Error('Keine Abschnitte gefunden');
    }
  
    try {
      console.log('Starting batched recommendations generation');

      setProcessingStatus({
        message: `Starte Empfehlungsgenerierung für ${allSections.length} Abschnitte`,
        severity: 'info',
        isProcessing: true
      });
      
      const BATCH_SIZE = 3; // Process 3 sections at a time
      const allRecommendations = {};
      const totalBatches = Math.ceil(allSections.length / BATCH_SIZE);
      
      // Process sections in batches
      for (let i = 0; i < allSections.length; i += BATCH_SIZE) {
        const batchSections = allSections.slice(i, i + BATCH_SIZE);
        console.log(`Processing recommendations batch ${Math.floor(i/BATCH_SIZE) + 1} of ${Math.ceil(allSections.length/BATCH_SIZE)}`);
        const currentBatch = Math.floor(i / BATCH_SIZE) + 1;
        setProcessingStatus({
          message: `Generiere Empfehlungen: Batch ${currentBatch} von ${totalBatches}`,
          severity: 'info',
          isProcessing: true
        });
        const recommendationsPrompt = {
          role: "system",
          content: `Du bist ein erfahrener Rechtsberater. Generiere für jeden Abschnitt 2-3 fundierte Empfehlungen. Wenn keine spezifischen Empfehlungen möglich sind, gib ein leeres Array zurück.
          Berücksichtige dabei:
          
          - Rechtliche Präzision und Compliance
          - Risikominimierung und Absicherung
          - Bewährte Praktiken für diese Art von Klauseln
          - Typische Problemstellen und Fallstricke
          - Schweizer Rechtspraxis und Sprachgebrauch
          
          Liefere die Empfehlungen in folgendem JSON-Format:
          {
            "section_identifier": {
              "recommendations": [
                {
                  "id": "empfehlungs_id",
                  "text": "empfehlungstext",
                  "type": "verbesserungstyp",
                  "confidence": "hoch|mittel|niedrig",
                  "reasoning": "kurze_begründung"
                }
              ]
            }
          }
          
          Beachte: Generiere Empfehlungen nur für die angegebenen Abschnitte.`
        };
  
        const sectionsPrompt = {
          role: "user",
          content: JSON.stringify({ sections: batchSections })
        };
  
        try {
          const batchRecommendationsResponse = await queryGenerativeModel(
            model_params.provider,
            model_params.model,
            [recommendationsPrompt, sectionsPrompt],
            model_params.response_format
          );
          
          if (!batchRecommendationsResponse) {
            console.warn(`No recommendations received for batch ${Math.floor(i/BATCH_SIZE) + 1}`);
            continue;
          }
  
          const batchRecommendations = JSON.parse(batchRecommendationsResponse);
          
          // Merge batch recommendations into overall recommendations
          Object.assign(allRecommendations, batchRecommendations);
          
          console.log(`Successfully processed recommendations for batch ${Math.floor(i/BATCH_SIZE) + 1}`);
          
          // Add a small delay between batches to avoid rate limiting
          await new Promise(resolve => setTimeout(resolve, 1000));
          
        } catch (batchError) {
          console.error(`Error processing recommendations batch ${Math.floor(i/BATCH_SIZE) + 1}:`, batchError);
          // Continue with next batch instead of failing completely
          setProcessingStatus({
            message: `Warnung: Probleme bei Batch ${currentBatch}. Fahre mit nächstem Batch fort...`,
            severity: 'warning',
            isProcessing: true
          });
          continue;
        }
      }

      setProcessingStatus({
        message: 'Führe finale Verarbeitung durch...',
        severity: 'info',
        isProcessing: true
      });
  
      console.log('Completed recommendations generation');
      
      // Process sections into final format
      const processedSections = {};
      const hierarchy = {};
      
      allSections.forEach(section => {
        if (!section.isPartial && section.identifier) {
          // Check if we have recommendations for this section
          const sectionRecommendations = allRecommendations[section.identifier]?.recommendations || [];
          
          // If no recommendations were generated, create a default one
          if (sectionRecommendations.length === 0) {
            sectionRecommendations.push({
              id: `default_${section.identifier}`,
              text: "Keine spezifische Empfehlung verfügbar. Bitte prüfen Sie diesen Abschnitt manuell.",
              type: "standard",
              confidence: "niedrig",
              reasoning: "Automatische Empfehlung konnte nicht generiert werden."
            });
          }
  
          processedSections[section.identifier] = {
            identifier: section.identifier,
            title: section.title || '',
            originalText: section.text || '',
            type: section.type || 'unknown',
            //level: section.level || 0,
            parentIdentifier: section.parentIdentifier || null,
            childIdentifiers: Array.isArray(section.childIdentifiers) ? section.childIdentifiers : [],
            recommendations: sectionRecommendations,
            needsRecommendation: true,
            selectedRecommendation: null,
            isModified: false,
            manualInput: ''
          };
          
          hierarchy[section.identifier] = {
            parentIdentifier: section.parentIdentifier || null,
            childIdentifiers: Array.isArray(section.childIdentifiers) ? section.childIdentifiers : []
          };
        }
      });
  
      console.log('Final processing complete', {
        totalSections: Object.keys(processedSections).length,
        hierarchySize: Object.keys(hierarchy).length,
        sectionsWithRecommendations: Object.keys(allRecommendations).length
      });

      setProcessingStatus({
        message: `Verarbeitung abgeschlossen: ${Object.keys(processedSections).length} Abschnitte analysiert`,
        severity: 'success',
        isProcessing: false
      });
  
      if (Object.keys(processedSections).length === 0) {
        throw new Error('Keine verarbeitbaren Abschnitte gefunden');
      }
  
      return {
        sections: processedSections,
        hierarchy
      };
  
    } catch (error) {
      console.error('Error in recommendation processing:', error);
      throw new Error(`Fehler bei der Empfehlungsverarbeitung: ${error.message}`);
    }
  }
  
  function splitTextIntoChunks(text, chunkSize) {
    if (!text) {
      throw new Error('Kein Text zum Aufteilen vorhanden');
    }
  
    const chunks = [];
    let currentChunk = '';
    
    const paragraphs = text.split(/\n\s*\n/);
    
    for (const paragraph of paragraphs) {
      if ((currentChunk + paragraph).length > chunkSize && currentChunk.length > 0) {
        chunks.push(currentChunk.trim());
        currentChunk = paragraph;
      } else {
        currentChunk += (currentChunk ? '\n\n' : '') + paragraph;
      }
    }
    
    if (currentChunk) {
      chunks.push(currentChunk.trim());
    }
    
    return chunks;
  }
  
  function mergeSections(existingSections, newSections) {
    if (!Array.isArray(existingSections) || !Array.isArray(newSections)) {
      console.error('Invalid input to mergeSections:', { existingSections, newSections });
      return existingSections || [];
    }
  
    const merged = [...existingSections];
    
    for (const newSection of newSections) {
      if (!newSection.identifier) {
        console.warn('Section without identifier found:', newSection);
        continue;
      }
  
      const existingIndex = merged.findIndex(s => 
        (s.identifier === newSection.continuesFromIdentifier) || 
        (s.identifier === newSection.identifier && s.isPartial)
      );
      
      if (existingIndex >= 0) {
        // Merge with existing section
        merged[existingIndex] = {
          ...merged[existingIndex],
          text: (merged[existingIndex].text || '') + '\n' + (newSection.text || ''),
          isPartial: newSection.isPartial,
          continuesInIdentifier: newSection.continuesInIdentifier,
          // Preserve the original identifier and hierarchy information
          identifier: merged[existingIndex].identifier,
          parentIdentifier: merged[existingIndex].parentIdentifier,
          childIdentifiers: merged[existingIndex].childIdentifiers
        };
      } else {
        // Add new section
        merged.push(newSection);
      }
    }
    
    return merged;
  }

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    setError(null);

    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      setFile(uploadedFile);
      setIsLoading(true);
      logAction('System', `PDF-Datei hochgeladen: ${uploadedFile.name}`);

      setProcessingStatus({
        message: `PDF-Datei "${uploadedFile.name}" wird analysiert...`,
        severity: 'info',
        isProcessing: true
      });

      try {
        const pdfData = await parsePdfFile(uploadedFile);
        console.log('PDF-Daten:', pdfData);
        
        if (pdfData.error) {
          throw new Error(pdfData.error);
        }

        setPdfContent(pdfData);
        const processedData = await processPdfContent(pdfData);
        
        setSections(processedData.sections);
        setHierarchy(processedData.hierarchy);
        setShowSections(true);
        logAction('System', `PDF erfolgreich verarbeitet mit ${pdfData.pages} Seiten`);
      } catch (err) {
        console.error('Vollständiger Fehler:', err);
        setProcessingStatus({
          message: `Fehler bei der PDF-Verarbeitung: ${err.message}`,
          severity: 'error',
          isProcessing: false
        });
        setError(`Fehler bei der PDF-Verarbeitung: ${err.message}`);
        logAction('System', `PDF-Verarbeitung fehlgeschlagen: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('Ungültiges Dateiformat - nur PDF-Dateien sind erlaubt');
      logAction('System', 'Ungültiges Dateiformat verwendet');
      setProcessingStatus({
        message: 'Ungültiges Dateiformat - nur PDF-Dateien sind erlaubt',
        severity: 'error',
        isProcessing: false
      });
    }
  };

  const logAction = (section, action) => {
    const timestamp = new Date().toLocaleTimeString();
    setActionLog(prev => [...prev, `${timestamp} - ${section}: ${action}`]);
  };

  const handleNeedsRecommendation = (sectionName, value) => {
    setSections(prev => ({
      ...prev,
      [sectionName]: {
        ...prev[sectionName],
        needsRecommendation: value,
        selectedRecommendation: null,
        isModified: false,
        manualInput: '',
      }
    }));
    logAction(sectionName, `Empfehlung benötigt: ${value ? 'Ja' : 'Nein'}`);
  };

  const handleSelectRecommendation = (sectionName, recommendation) => {
    setSections(prev => ({
      ...prev,
      [sectionName]: {
        ...prev[sectionName],
        selectedRecommendation: recommendation,
        isModified: false,
        manualInput: '',
      }
    }));
    logAction(sectionName, `Empfehlung ausgewählt: ${recommendation.text}`);
  };

  const handleModifyRecommendation = (sectionName, modifiedText) => {
    setSections(prev => ({
      ...prev,
      [sectionName]: {
        ...prev[sectionName],
        isModified: true,
        manualInput: modifiedText,
      }
    }));
    logAction(sectionName, `Empfehlung angepasst: ${modifiedText}`);
  };

  const handleManualInput = (sectionName, text) => {
    setSections(prev => ({
      ...prev,
      [sectionName]: {
        ...prev[sectionName],
        selectedRecommendation: null,
        isModified: false,
        manualInput: text,
      }
    }));
    logAction(sectionName, `Manuelle Eingabe: ${text}`);
  };

  const generateSummary = () => {
    const summary = {};
    Object.entries(sections).forEach(([sectionName, section]) => {
      if (section.needsRecommendation) {
        summary[sectionName] = {
          selectedText: section.manualInput 
            ? section.manualInput 
            : section.isModified 
            ? section.manualInput 
            : section.selectedRecommendation?.text || 'Keine Auswahl',
          type: section.manualInput 
            ? 'Manuelle Eingabe' 
            : section.isModified 
            ? 'Modifizierte Empfehlung' 
            : 'Standard Empfehlung'
        };
      } else {
        summary[sectionName] = {
          selectedText: 'Keine Empfehlung benötigt',
          type: 'Übersprungen'
        };
      }
    });
    return summary;
  };

  const handleSave = () => {
    const saveData = {
      timestamp: new Date().toISOString(),
      fileName: file?.name || 'Keine Datei',
      pdfMetadata: {
        pages: pdfContent?.pages || 0,
        filename: pdfContent?.filename || '',
      },
      sections: Object.entries(sections).map(([sectionName, section]) => ({
        sectionName,
        originalText: section.originalText,
        needsRecommendation: section.needsRecommendation,
        finalRecommendation: section.manualInput 
          ? section.manualInput 
          : section.isModified 
          ? section.manualInput 
          : section.selectedRecommendation?.text || 'Keine Auswahl',
        recommendationType: section.manualInput 
          ? 'Manuelle Eingabe' 
          : section.isModified 
          ? 'Modifizierte Empfehlung' 
          : 'Standard Empfehlung'
      })),
      actionLog
    };

    const blob = new Blob([JSON.stringify(saveData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `empfehlungen_${file.name.replace('.pdf', '')}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    logAction('System', 'Empfehlungszusammenfassung gespeichert');
  };

  return (
    <Box sx={{ margin: '0 auto', gap: 2, display: 'flex', flexDirection: 'column' }}>
      <Card variant="outlined">
        <CardHeader title="PDF-Datei hochladen" sx={{ bgcolor: 'grey.100' }} />
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              variant="contained"
              component="label"
              startIcon={<UploadIcon />}
              disabled={isLoading}
            >
              PDF auswählen
              <input
                type="file"
                hidden
                accept="application/pdf"
                onChange={handleFileUpload}
              />
            </Button>
            {file && (
              <Typography>
                Ausgewählte Datei: {file.name}
              </Typography>
            )}
           {/*  {isLoading && <CircularProgress size={24} />} */}
          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {error}
            </Alert>
          )}
          {pdfContent && processingStatus.message && (
              <Alert 
                severity={processingStatus.severity}
                sx={{ mt: 2 }}
                icon={processingStatus.isProcessing ? <CircularProgress size={20} /> : undefined}
              >
                {processingStatus.message}
              </Alert>
          )}
        </CardContent>
      </Card>
  
      {/* Main Content Area */}
      {showSections && (
        <>
          {/* Document Structure and Section Details Container */}
          <Box sx={{ display: 'flex', gap: 2, minHeight: '600px' }}>
            {/* Left: Document Structure */}
            <Box sx={{ width: '300px', flexShrink: 0 }}>
              <HierarchicalVisualization
                sections={sections}
                hierarchy={hierarchy}
                onSectionSelect={setSelectedSectionId}
                selectedSectionId={selectedSectionId}
              />
            </Box>
  
            {/* Right: Section Details */}
            <Box sx={{ flex: 1 }}>
              {selectedSectionId && sections[selectedSectionId] ? (
                <Card variant="outlined">
                  <CardHeader
                    title={`${sections[selectedSectionId].identifier} ${sections[selectedSectionId].title || ""}`}
                    sx={{ bgcolor: 'grey.100' }}
                  />
                  <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {/* Original Text */}
                      <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.50' }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Original Text:
                        </Typography>
                        <Typography variant="body2">
                          {sections[selectedSectionId].originalText}
                        </Typography>
                      </Paper>
  
                      {/* Need for Recommendation */}
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <ErrorOutline color="primary" />
                        <Typography>Braucht dieser Abschnitt eine Empfehlung?</Typography>
                        <Box>
                          <Button
                            variant={sections[selectedSectionId].needsRecommendation ? "contained" : "outlined"}
                            onClick={() => handleNeedsRecommendation(selectedSectionId, true)}
                            size="small"
                            sx={{ mr: 1 }}
                          >
                            Ja
                          </Button>
                          <Button
                            variant={!sections[selectedSectionId].needsRecommendation ? "contained" : "outlined"}
                            onClick={() => handleNeedsRecommendation(selectedSectionId, false)}
                            size="small"
                          >
                            Nein
                          </Button>
                        </Box>
                      </Box>
  
                      {sections[selectedSectionId].needsRecommendation && (
                        <>
                          {/* Available Recommendations */}
                          <Box sx={{ mt: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                              <List color="primary" />
                              <Typography>Verfügbare Empfehlungen:</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                              {sections[selectedSectionId].recommendations.map((rec) => (
                                <Button
                                  key={rec.id}
                                  variant={sections[selectedSectionId].selectedRecommendation?.id === rec.id ? "contained" : "outlined"}
                                  onClick={() => handleSelectRecommendation(selectedSectionId, rec)}
                                  sx={{ justifyContent: 'flex-start', textAlign: 'left' }}
                                >
                                  {rec.text}
                                </Button>
                              ))}
                            </Box>
                          </Box>
  
                          {/* Modify Recommendation */}
                          {sections[selectedSectionId].selectedRecommendation && (
                            <Box sx={{ mt: 2 }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                <Edit color="primary" />
                                <Typography>Empfehlung anpassen:</Typography>
                              </Box>
                              <TextField
                                fullWidth
                                multiline
                                rows={3}
                                value={
                                  sections[selectedSectionId].isModified
                                    ? sections[selectedSectionId].manualInput
                                    : sections[selectedSectionId].selectedRecommendation.text
                                }
                                onChange={(e) => handleModifyRecommendation(selectedSectionId, e.target.value)}
                                variant="outlined"
                              />
                            </Box>
                          )}
  
                          {/* Manual Input */}
                          <Box sx={{ mt: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                              <AddCircleOutline color="primary" />
                              <Typography>Manuelle Empfehlung hinzufügen:</Typography>
                            </Box>
                            <TextField
                              fullWidth
                              multiline
                              rows={3}
                              value={sections[selectedSectionId].manualInput}
                              onChange={(e) => handleManualInput(selectedSectionId, e.target.value)}
                              placeholder="Manuelle Empfehlung eingeben..."
                              variant="outlined"
                            />
                          </Box>
  
                          {/* Current Selection Display */}
                          <Paper sx={{ mt: 2, p: 2, bgcolor: 'grey.50' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                              <CheckCircle color="success" />
                              <Typography variant="subtitle1">Aktuelle Auswahl:</Typography>
                            </Box>
                            <Typography>
                              {sections[selectedSectionId].manualInput
                                ? sections[selectedSectionId].manualInput
                                : sections[selectedSectionId].isModified
                                ? sections[selectedSectionId].manualInput
                                : sections[selectedSectionId].selectedRecommendation?.text || 'Keine Auswahl'}
                            </Typography>
                          </Paper>
                        </>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                <Card variant="outlined">
                  <CardContent>
                    <Typography color="textSecondary">
                      Bitte wählen Sie einen Abschnitt aus der Dokumentstruktur aus
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Box>
          </Box>
  
          {/* Summary and Save */}
          <Card variant="outlined">
            <CardHeader
              title="Zusammenfassung der Auswahl"
              sx={{ bgcolor: 'grey.100' }}
            />
            <CardContent>
              <Box sx={{ mb: 2 }}>
                {Object.entries(generateSummary()).map(([section, data]) => (
                  <Box key={section} sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {section}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Typ: {data.type}
                    </Typography>
                    <Typography variant="body1">
                      {data.selectedText}
                    </Typography>
                    <Divider sx={{ mt: 1 }} />
                  </Box>
                ))}
              </Box>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSave}
                sx={{ mt: 2 }}
              >
                Auswahl speichern
              </Button>
            </CardContent>
          </Card>


          {/* Action Log */}
          <Card variant="outlined">
            <CardHeader
              title="Aktivitätsprotokoll"
              sx={{ bgcolor: 'grey.100' }}
            />
            <CardContent>
              <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
                {actionLog.map((log, index) => (
                  <Typography key={index} variant="body2" color="text.secondary">
                    {log}
                  </Typography>
                ))}
              </Box>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
};

export default RecommendationWorkflow;